import React, { PropsWithChildren } from 'react'
import { Breakpoint, Container, Theme } from '@mui/material'
import { useBreakpoint, useBreakpoints } from '~layout'
import { makeStyles } from '@mui/styles'
import ClassNames from 'classnames'
import { Flex, FlexProps } from 'mui-blox'

interface FrameProps extends FlexProps {
  className?: string
  hideScrollbar?: boolean
}

const ExconFrame = ({ children, className, hideScrollbar }: PropsWithChildren<FrameProps>): JSX.Element => {
  const classes = useStyles()
  const isSm = useBreakpoint('md').down
  const { current } = useBreakpoints()

  return (
    <Flex
      flexColumn
      className={ClassNames(classes.root, className, { [classes.overflow]: !hideScrollbar })}
      sx={{ backgroundColor: 'background.default' }}
    >
      <Container
        role='main'
        maxWidth={current as Breakpoint}
        disableGutters={isSm}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          backgroundColor: 'background.default',
        }}
      >
        {children}
      </Container>
    </Flex>
  )
}

const useStyles = makeStyles<Theme & { layout: { topBarMedium: number } }>(
  ({ shadows, spacing, zIndex, layout }) => ({
    root: {
      position: 'relative',
    },
    navButtons: () => ({
      height: '22px',
      position: 'absolute',
      top: `${layout.topBarMedium - 11}px`,
      left: spacing(1.5),
      zIndex: zIndex.appBar + 1,
      boxShadow: shadows[3],
    }),
  }),
)

export default ExconFrame
