import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Flex } from 'mui-blox'

const Markdown = ({ className, source, renderers }) => {
  const classes = useStyles()

  const rendererOverrides = {
    heading: ({ children, level }) => (
      <Box mt={1} mb={0.5}>
        <Typography variant={`h${level}`} className={classes.heading}>
          {children}
        </Typography>
      </Box>
    ),
    // paragraph: ({ children }) => {
    //   const hasImageOrLink = !!children.find(
    //     child =>
    //       (typeof child === 'object' && child.key && Boolean(child.key.match(/image/g))) ||
    //       Boolean(child.key.match(/link/g)),
    //   )
    //   return hasImageOrLink ? children : <Typography className={classes.paragraph}>{children}</Typography>
    // },
    link: ({ href, children }) => {
      return (
        <a href={href} target='_blank' rel='noopener noreferrer'>
          {children[0].props.children}
        </a>
      )
    },
    ...renderers,
  }

  return (
    <Flex flexDirection='column' overflow='hidden' className={classNames(className, classes.font)}>
      <ReactMarkdown plugins={[gfm]} source={source} linkTarget='_blank' renderers={rendererOverrides} />
    </Flex>
  )
}

Markdown.propTypes = {
  className: PropTypes.string,
  source: PropTypes.string.isRequired,
  renderers: PropTypes.object,
}

Markdown.defaultProps = {
  renderers: null,
}

const useStyles = makeStyles(({ spacing, typography }) => ({
  paragraph: {
    marginBottom: spacing(1),
  },
  heading: {
    marginTop: spacing(1, 0),
  },
  font: {
    fontFamily: typography.fontFamily,
    '& li': {
      marginBottom: spacing(0.5),
    },
  },
}))

export default Markdown
