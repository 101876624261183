import { typify } from './data/typify'

export const getFromOption = (option, key) => {
  const resolved = typify.isArray(option) ? option[0] : option

  if (typify.isObject(resolved)) return resolved[key]
  if (typify.isMap(resolved)) return resolved.get('key')

  return resolved != null ? resolved : ''
}

export const getResolvedValue = value => {
  const isImmutable = typify.isList(value)
  let resolvedValue = value

  if (isImmutable) resolvedValue = value.toJS()

  if (typeof resolvedValue === 'string' && resolvedValue === '') {
    resolvedValue = null
  }

  return { resolvedValue, isImmutable }
}
