import React, { useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { LogoTiny } from '../logo/LogoTiny'
import { ImmutableMap } from '~core/botPlan/executors/types'
import { BotMenuSettings } from './ExconTopBar'
import { fromJS, List } from 'immutable'
import { POWER_UP_KEYS } from '~constants'
import { useSetExconTitle } from '~layout'
import { useLocation } from 'react-router'

interface FooterProps {
  settings: ImmutableMap<{
    branding: Record<string, unknown>
    powerUpSettings: { botMenu?: BotMenuSettings }
    hostname?: string
    eventId: string
  }>
}

export const ExconFooter = ({ settings }: FooterProps) => {
  const botMenuSettings: ImmutableMap<BotMenuSettings> = settings.getIn(
    ['powerUpSettings', POWER_UP_KEYS.botMenu],
    fromJS({}),
  ) as ImmutableMap<BotMenuSettings>
  const { hideTitleBar } = useSetExconTitle()
  const { pathname } = useLocation()

  const shellEnabled = useMemo(() => {
    const homePageEnabled = botMenuSettings.get('homePageEnabled', false)
    const currentExcon = botMenuSettings
      .get('availableExcons', List())
      .find((excon: ImmutableMap<{ exconId: string }>) => {
        return pathname.indexOf(excon.get('exconId', '')) > 0
      })

    return homePageEnabled && !!currentExcon
  }, [botMenuSettings, pathname])

  if (shellEnabled || hideTitleBar) return null

  return (
    <Stack
      p={1}
      alignItems='center'
      justifyContent='center'
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: '0 0 15px 15px',
        borderTop: '2px solid',
        borderTopColor: 'grey.200',
        height: 40,
      }}
      flexDirection='row'
    >
      <Typography
        color='text.primary'
        textAlign={'center'}
        variant='h6'
        sx={{ display: 'inline', fontSize: '0.875rem', fontWeight: 500, marginRight: 0.5 }}
      >
        Powered by
      </Typography>
      <Stack position='relative' width={60}>
        <LogoTiny width={60} sx={{ color: 'text.primary', position: 'absolute', top: -11 }} />
      </Stack>
    </Stack>
  )
}

export default ExconFooter
