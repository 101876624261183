import React, { forwardRef } from 'react'
import { fromJS, Map, List } from 'immutable'
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  createFilterOptions,
  TextField,
  Chip,
  CircularProgress,
  InputAdornment,
  FilterOptionsState,
  AutocompleteInputChangeReason,
} from '@mui/material'
import { typify } from '../../../core/util/data/typify'
import { getFromOption, getResolvedValue } from '../../../core/util/autoCompleteUtils'
import { keyboardCodes } from '~appUtils'

export { createFilterOptions }

type Option = string | number | Record<string, unknown> | unknown | Map<string, string>

export type AutocompleteFieldProps<OptionType = Option> = Record<string, unknown> & {
  onChange: (o: OptionType, reason?: string) => void
  options: OptionType[] | List<OptionType>
  value: unknown
  // optional props
  disableClearable?: boolean
  disableCloseOnSelect?: boolean
  disabled?: boolean
  error?: boolean
  filterOptions?: (options: OptionType[], state: FilterOptionsState<OptionType>) => OptionType[]
  filterSelectedOptions?: boolean
  freeSolo?: boolean
  fullWidth?: boolean
  getOptionLabel?: (o: OptionType) => string
  getOptionValue?: (o: OptionType) => unknown
  helperText?: string
  hiddenLabel?: boolean
  InputComponent?: React.FunctionComponent
  InputProps?: Record<string, unknown>
  isLoading?: boolean
  label?: React.ReactNode
  multiple?: boolean
  normalizeValue?: (o: OptionType) => unknown
  normalizeInputValue?: (v: string) => string
  onBlur?: (event: any) => void
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => void
  renderOption?: (props: unknown, o: OptionType, state?: unknown) => React.ReactNode
  renderTags?: AutocompleteProps<OptionType, true, true, false>['renderTags']
  size?: 'small' | 'medium'
  inputStyle?: React.CSSProperties
  style?: React.CSSProperties
}

const AutocompleteField = forwardRef<HTMLInputElement, AutocompleteFieldProps>((props, ref) => {
  const {
    error = false,
    filterOptions,
    freeSolo = false,
    getOptionLabel = o => getFromOption(o, 'label'),
    getOptionValue = o => getFromOption(o, 'value'),
    helperText,
    hiddenLabel,
    InputComponent = TextField,
    InputProps = {},
    isLoading = false,
    label = '',
    multiple = false,
    normalizeValue = v => v,
    normalizeInputValue,
    onChange,
    onInputChange,
    options = [],
    renderOption,
    renderTags,
    size,
    value,
    inputStyle,
    ...rest
  } = props

  const { resolvedValue, isImmutable } = getResolvedValue(value)

  if (typify.isString(value) && multiple) {
    console.warn('If multiple is enabled, value must be of type Array or List', value)
  }
  if (!typify.isArray(options) && !typify.isList(options)) {
    console.warn('AutocompleteField currently only supports options of type Array or List', options)
  }

  const resolvedOptions = typify.isList(options) ? (options as List<Option>).toJS() : (options as Option[])

  const selected = freeSolo
    ? resolvedValue
    : multiple
    ? resolvedValue.filter(o => resolvedOptions.some(v => getOptionValue(v) === getOptionValue(o)))
    : resolvedOptions.find(o => {
        return resolvedValue === getOptionValue(o)
      }) || resolvedValue

  const handleChange = (e, selected, reason?: string) => {
    const changeValue = multiple ? selected.map(o => getOptionValue(o)) : getOptionValue(selected)
    const normalized = normalizeValue(isImmutable ? fromJS(changeValue) : changeValue)

    onChange(normalized, reason)
  }

  const handleRenderTags = !multiple
    ? undefined
    : renderTags ??
      ((currentValue, getTagProps) => {
        return currentValue.map((o, index) => {
          // key prop provided by getTagProps
          // eslint-disable-next-line react/jsx-key
          return <Chip label={getOptionLabel(o)} size={size} {...getTagProps({ index })} />
        })
      })

  if (isLoading && React.createElement(InputComponent).type === TextField) {
    InputProps.endAdornment = (
      <InputAdornment position='end'>
        <CircularProgress size={25} />
      </InputAdornment>
    )
  }

  return (
    <Autocomplete
      ref={ref}
      clearOnBlur
      filterOptions={filterOptions}
      getOptionLabel={(o: string | string[]) => {
        const resolved = typify.isArray(o) ? o[0] : o

        return o != null && o !== '' ? getOptionLabel(resolved) : ''
      }}
      loading={isLoading}
      multiple={multiple}
      onChange={handleChange}
      onInputChange={onInputChange}
      options={resolvedOptions}
      renderInput={(params: AutocompleteRenderInputParams) => {
        if (freeSolo) {
          const inputValue = (params.inputProps as Record<string, unknown>).value

          ;(params.inputProps as Record<string, unknown>).onKeyDown = e => {
            if (e.keyCode === keyboardCodes.Enter) {
              e.preventDefault()
              handleChange(e, multiple ? [...selected, inputValue] : inputValue)
            }
          }
        }

        return (
          <InputComponent
            {...params}
            label={label}
            error={error}
            helperText={helperText}
            hiddenLabel={hiddenLabel}
            InputProps={{ ...InputProps, ...params.InputProps, size }}
            style={inputStyle}
          />
        )
      }}
      renderOption={renderOption}
      renderTags={handleRenderTags}
      selectOnFocus
      size={size}
      value={selected}
      componentsProps={{ paper: { elevation: 3 } }}
      {...rest}
    />
  )
})

AutocompleteField.displayName = 'AutoCompleteField'

export default AutocompleteField
