import React from 'react'
import { Box, BoxProps } from '@mui/material'

export const LogoTiny = ({ sx, ...rest }: BoxProps) => {
  return (
    <Box
      {...rest}
      sx={{
        color: 'black',
        ...sx,
      }}
    >
      <svg viewBox='0 0 67 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='1.26941' width='23.8436' height='24.7315' rx='5' fill='url(#paint0_linear_657_12187)' />
        <path
          d='M22.2349 28.0291L17.7129 24.7315L21.4127 24.3193L22.2349 28.0291Z'
          fill='url(#paint1_linear_657_12187)'
        />
        <path
          d='M9.93807 11.6407H11.9531V17.5947H9.93807V15.6577H5.66107V13.8377L7.97507 8.36473H10.1851L7.87107 13.8377H9.93807V11.6407ZM19.2616 11.1857C19.2616 13.7207 15.3486 14.5657 14.9066 15.7747H19.1706V17.5947H12.6706V16.6457C12.6706 13.6557 17.2466 12.7457 17.2466 11.1077V10.8347C17.2466 10.2887 16.7916 9.98973 16.0116 9.98973C15.2576 9.98973 14.7896 10.3927 14.7896 11.1207V11.4977H12.7746V11.2117C12.7746 9.37873 14.1006 8.16973 16.0506 8.16973C17.9746 8.16973 19.2616 9.22273 19.2616 10.8087V11.1857Z'
          fill='white'
        />
        <path
          d='M33.9945 18.255C31.5635 18.255 30.1015 16.793 30.1015 14.498V12.832C30.1015 10.537 31.5635 9.075 33.9775 9.075C36.2725 9.075 37.7685 10.503 37.7685 12.679V12.934H35.2015V12.611C35.2015 11.829 34.7085 11.37 33.9945 11.37C33.1105 11.37 32.6515 11.88 32.6515 12.849V14.481C32.6515 15.45 33.0935 15.96 33.9945 15.96C34.7085 15.96 35.2015 15.501 35.2015 14.719V14.396H37.7685V14.651C37.7685 16.827 36.2725 18.255 33.9945 18.255ZM43.879 9.075C45.817 9.075 47.143 10.214 47.143 12.968V18H44.593V12.934C44.593 11.897 43.947 11.387 43.233 11.387C42.536 11.387 41.839 11.863 41.839 12.781V18H39.289V4.91H41.839V9.602C42.434 9.245 43.131 9.075 43.879 9.075ZM54.8359 9.33H56.5189V18H54.8359L54.4279 17.422C53.7819 18 52.9149 18.255 51.9289 18.255C49.8549 18.255 48.5459 17.15 48.5459 14.583V12.747C48.5459 10.18 49.8549 9.075 51.9289 9.075C52.9149 9.075 53.7819 9.33 54.4279 9.908L54.8359 9.33ZM52.5069 15.943C53.3569 15.943 53.9689 15.484 53.9689 14.566V12.764C53.9689 11.846 53.3739 11.387 52.5069 11.387C51.6569 11.387 51.0959 11.863 51.0959 12.815V14.515C51.0959 15.467 51.6569 15.943 52.5069 15.943ZM62.4457 15.705H63.2787V18H61.8507C59.7257 18 58.6207 16.844 58.6207 14.549V11.625H57.4647V9.33H58.6207V6.899H61.1707V9.33H63.1597V11.625H61.1707V14.09C61.1707 15.144 61.6127 15.705 62.4457 15.705Z'
          fill='currentColor'
        />
        <defs>
          <linearGradient
            id='paint0_linear_657_12187'
            x1='7.43236'
            y1='-4.18645e-05'
            x2='21.5508'
            y2='0.405736'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#6BA843' />
            <stop offset='1' stopColor='#88BF43' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_657_12187'
            x1='18.8817'
            y1='24.3193'
            x2='21.558'
            y2='24.4166'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#6BA843' />
            <stop offset='1' stopColor='#88BF43' />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )
}
